/* enquiry context menu and enquiry menu items */
.enquiry-context-menu-items, .enquiry-menu-items {
    position: absolute;
    padding: 10px;
    z-index: 100000000;
    width: 215px;
}

.enquiry-context-menu-items .option, .enquiry-menu-items .option {
    cursor: pointer;
    pointer-events: all;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.enquiry-context-menu-items .option.-disabled, .enquiry-menu-items .option.-disabled {
    cursor: default;
    pointer-events: none;
}
.enquiry-context-menu-items .option.-hidden, .enquiry-menu-items .option.-hidden {
    display: none;
}
.enquiry-burger-button {
    padding: 3px;
    margin-left: 5px;
}

/* enquiry dropdown burger menu */
.drpdown {
    position: relative;
    float: right;
}
.drpdown-menu {
    width: fit-content;
    display: block;
    position: absolute;
    left: -100px;
    top: 25px;
    z-index: 100000;
}
 

.workflow-header {
    padding: 0px 1px;
     width: 100%;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.actions {


    .heading {
        margin: 8px 0px 16px 0px;
 
    }
    .Subheading{
        margin: 4px 0px;
        font-weight:bold;
    }
    .action-list {
        height: 442px;
        overflow: auto;
       input[type="radio"] {
            display: none;
        }
        .enquiry-action {
            width: 100%;
            height: 40px;
            cursor: pointer;
            display: flex;
            gap: 2px;
            align-items: center;
            &--icon {
                height: 20px;
                width: 20px;
                margin: 9px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                    width: 10px;
                    height: 10px;
                }
            }
 
            &--details {

                display: flex;
                flex-direction: column;
                justify-content: space-around;
                overflow: hidden;
                padding: 0px 4px;
                flex: 2 1 0;
            }

            &--checkbox-feedback-control {
                margin-right: 9px;
                display: flex;
                // align-items: center;
                gap: 6px
            }
        }

        .enquiry-action.default {
            cursor: not-allowed;
        }
    }
}
.feedback-control{
    margin-right: 10px;
    display: flex;
    // align-items: center;
    gap: 6px;

}
.editBackground {
    pointer-events: all;
    opacity: 1;
    width: 20px;
    height: 20 px;
    border-Radius: 13px;
    text-Align: center;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        width: 10px;
    }
}
.enquiry-tracter--header {
    margin-bottom: 10px;
}
.enquiry-tracter--options {
    overflow: hidden;
    &--option {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}
.step {
    width: 100%;
    height: 100%;
    margin-bottom: 6px;
    margin-right:10px;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    
    &--header {
        height: 43px;
        display: flex;
        align-items: center;

        .chevron {
            width: 28px;
            justify-self: center;
        }

        .edit-icon {
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-bg {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }



    .show {
        display: inline;
        flex: 9 1 0;
        overflow: hidden;
    }
}

@media screen and (min-width: 1062px) {
    .step {
        // height: calc(100vh - 148px);
    }
  }


.hide {
    display: none !important;
}

.step.inactive {
 

    .enqTitle {
        display: block;
        margin: 2px 0px;
   
    }

     

}

// #3F4D67
.activeStep {


    &>div {
        align-items: center;
    }

    .enqTitle {
 
        display: block;
        margin-top: 2px;
        margin-bottom: 4px;
        margin-left: 28px;
 
    }

    .editBackground {
        pointer-events: none;
        opacity: 0;
    }


}
 
.disabled {
    pointer-events: none;
    margin-top: 6px;

    .icon-bg {
        display: none !important;
    }
}

i .Chevron {

    font-size: 20px;
    width: 12px;
    align-self: center;
}

i {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}



// .hide {
//     display: none !important;
// }

// .show {
//     display: inline;

// }

.enqButton {

    align-items: center;
    margin-top: 0px;
    margin-left: 0.3em;
    height: 25px;
 
    border: 0px;
 
    padding: 0px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        padding: 0px;
        margin: 0px;
    }
}

// Accordion for search step
.accord {
    display: flex;

    overflow: hidden;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.13);
}
.accord-head {
    padding: 0px 10px;
    height: 40px;
    flex-basis: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
}
.accord-content {
    height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.search-fields {
    padding: 0px 10px;
}
.buttons-sect {
    display: flex;
}
.enquiries-bar-nav {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: space-between;
}
.enquiries-result {
    flex: 6 1 0;
    overflow: auto;
}
.accord.active {
    overflow: hidden;
    flex: 6 1 0;

    .accord-content {
        flex: 6 1 0;
        overflow: hidden;
        padding: 10px 0px;
    }
    .accord-arrow {
        transform: rotate(90deg);
    }
}
// end accordion

.required:after {
    content:" *";
    color: red;
}

.formPadding {
    padding-top: 3px;
}

.formRowMargin {
    margin-bottom: 5px;
}

.formRowMargin sup {
    color: red;
}

.buttonRow {
    text-Align: right;
    margin-Top: 5px 10px
}

.buttonRow button {
    margin-left: 10px;
    margin-right: 10px;
}

.composeEmail{
    display: flex;
    flex-direction: row ;
    flex-wrap: nowrap;
    align-content: flex-start;
    flex-flow: column;
    height: 100%;
}
.composeEmail .titleRow{
 
    display: inline-flex;
    width: min-content;
}
.composeEmail .titleRow P
{
    font-size:12px;
    font-weight:bold;
    padding:5px 10px 5px 10px;
    margin:0px;
}
.composeEmailRow{
 
    border-collapse: collapse;
    align-content: flex-start;
    display: flex;
    flex: 1;
 
    
}

.tox.tox-tinymce{
    flex: 22 1 0;
}

.composeAttachmentRowNoBorder{ //need this class for the intial render when there are no attachments
    border: 0px solid #dedede;
    border-collapse: collapse;
    align-content: flex-start;
    display: flex;
    flex-direction:row;
    padding:5px;
}
.composeAttachmentRow{
    flex-wrap: wrap;
    border: 1px solid #dedede;
    border-collapse: collapse;
    align-content: flex-start;
    display: flex;
    flex-direction:row;
    padding:5px;
    padding-bottom:0px;
}

.email-priority {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: 0;

    .priority-icon {
        width: 13px;
    }
}

/* toolbar dropdown */
.tlbar-dropdown {
    border-right: solid 1px #EDEDED;
    position: relative;
    display: flex;
    flex-direction: column;
}
.tlbar-dropdown-list {
    position: absolute;
    box-shadow: -1px 0px 19px -3px rgba(0,0,0,0.75);
    background: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 99;
    padding: 10px;
    width: 205px;
    font-size: 8pt;
    top: 90px;
    right: 20px;
}

.tlbar-optn {
    display: flex;
    align-items: center;
    gap: 6px;
}
.tlbar-optn-icon {
    color: black;
    display: flex;
    justify-content: center;
    margin-top:-2px;
    height:15px;
}
.tlbar-optn:hover .tlbar-optn-label {
    text-decoration: underline !important;
    cursor: pointer;
}

.AttachmentItem {    display: flex;    background-color:#E4E4E4;
    flex-direction:row;
    align-content: flex-start;
    padding:5px;
    margin-right:10px;
    margin-bottom:5px;
}
.composeAttachmentRow .AttachmentItem div:nth-child(1){
   
    width:auto;
 
}

.composeAttachmentRow .AttachmentItem div:nth-child(2){
    padding: 2px 5px 0px 10px;
    width:auto;
    font-size:8.5pt;
   
}

.AttachmentItem div:nth-child(2):hover{

    cursor: pointer;
    font-weight: normal;
}
.AttachmentItem div:nth-child(3){
    margin-top:-1px;
}
.AttachmentItem div:nth-child(3):hover{
    color:red;
    cursor: pointer;
    font-weight: normal;

}

.composeEmailRow > div   {
    flex: 1 ;
    padding:5px;
 
}

#composeEmailAttachmentRow{
    display:none;
}

.composeEmailRow div:nth-child(2), .composeEmailRow div:nth-child(4)
{
 
} 

.composeEmailRow div:nth-child(1), .composeEmailRow div:nth-child(3){ 
    flex: 0 0 25em;
    flex-basis:30px;
    padding-left: 15px;
    padding-top:7px;
    
} 
.composeBody{
    max-width: 100%;
    width: 100%;
    height:300px;
    border:0px;
    padding:10px;
}
.composeEmailTextBoxes{
    width:100%;
    border:0px;
}
.attachmentFlyout{

    width:200px;
    position:relative;
    float:left;
    margin-left:-7px;
    margin-top:-16px ;
    margin-bottom:-100px;
    padding:0px 0px 10px 0px;
   
    display:none;
}

.attachmentFlyout .attachmentFlyoutRow{
 
    display:flex;
    align-content: stretch ;
    flex: 0  35em;
    margin-left:-15px !important;
    padding: 0px !important;
    padding-top: 3px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.attachmentFlyout .attachmentFlyoutRow  div:nth-child(1){
    margin-top:-2px;
    padding:5px 0px 5px 10px;
    width:10px !important;
    
 
}

.attachmentFlyout .attachmentFlyoutRow  div:nth-child(2){
    padding-top:5px;
}

#hiddenFileUpload{
display:none;
}

#launchAttachment{
     padding-Left:8px;
     background:#fff !important;
     padding-bottom:8px;
}

#launchAttachment:hover{
    background:#F7F9FC !important;
    cursor: pointer;
}

.template-tab-padding{
    padding:11px 0px 9px 0px;
    margin-right:10px;
    margin-left:-0px !important; // need to overwrite the default margin of the column.
}

.embedGreenTickIcon{
    float:left;
    position:absolute; 
    margin-Left:-5px; 
    color:green
}

.embedRedTickIcon{
    float:left;
    position:absolute; 
    margin-Left:-5px; 
    color:red;
}

.responsedEmail{
    width:100%
}

.responsedEmailWithHeight{
    width:100%;
    height:750px;
}
//font -sizes
$font-size--xs: 8px;  // extra small
$font-size--sm: 10px; // small
$font-size--md: 12px; // medium
$font-size--lg: 14px; // large
//end font sizes
.g-badge {
    display: inline;
    padding: 4px 8px;
    margin: 0px;
    margin-right: 7px;
    border-radius: 2px;
    pointer-events: all;
    width: fit-content;
    align-items: center;
}

//form controls
.g-TextBox-default {
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    font-size: 9pt;
    height: 25px;
    padding: 5px;
    width: 100%;
    vertical-align: middle;
}

.g-TextArea-default {
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    font-size: 9pt;
    height: 60px;
    padding: 5px;
    min-width: 500px;
    min-height: 200px;
    max-height: 400px;
    vertical-align: middle;
}

.g-Select-default {
    width: 100%;
    font-size: 9pt;
    height: 25px;
    border-radius: 3px;
    border: solid 1px #CED4DA;
}

.g-label {
    font-size: 9pt;
    margin-top: 4px;
    display: inline-block;
}

.g-Checkbox-default {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
    border-radius: 0.25em;
    margin: 6px 8px 0 0;
}

.controlWithButton {
    display: flex;
}

.controlWithButton :nth-child(1) {
    flex: 1;
}

.controlWithButton :nth-child(2) {
    padding-left: 3px;
}

.controlWithButton :nth-child(2) button {
    color: White;
    cursor: pointer;
    padding: 2px 10px 3px;
    border: 0px;
    border-radius: 2px;
}

.rowMarginControl {
    margin-bottom: 8px;
}

 
// buttons
.g-btn-next-to-dropdown {
    cursor: pointer;
    padding: 2px 14px;
    border: 0px;
    border-radius: 2px;
}
.g-btn {
    cursor: pointer;
    padding: 2px 10px 3px;
    border: 0px;
    border-radius: 2px;
}

.g-btn-disabled {
    pointer-events: none;
    cursor: default;
}


.g-btn-icon-shadow {
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.note-attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 8px;
    background: #D3D3D3;
    font-size: $font-size--md;
    gap: 5px;

    .x {
        cursor: pointer;
    }
}

// end buttons

// Dropdown Nav

.g-dropdown-nav, .g-dropdown-nav-selected {
    border: 0px;
    position: relative;
    float: left;
    padding-top: 3px;
}
.g-dropdown-nav > label, 
.g-dropdown-nav-selected > label{
    margin-top:-3px;
    padding:16px;
    padding-bottom:13px;
    
}
.g-dropdown-nav:focus, .g-dropdown-nav-selected:focus{
    border:0px;
    outline:0px;
}
.g-dropdown-nav-list{
    width: fit-content;
    min-width: 150px;
    display: none;
    position: absolute;
    top: 51px;
    left: 0px;
    z-Index: 100000;
    margin: 0px;
    padding-top: 0px;
    text-align: left;
    margin-top:0px;

}

.g-dropdown-nav-selected .g-dropdown-nav-list{
    display: block;
   
}

.g-dropdown-nav-list>li {
    list-style: none;
    margin-left: -20px;
    padding: 8px 18px 10px 8px;
    color: #fff;
}
.g-dropdown-nav-list>li>button{
    background-color: transparent;
    border:0px;
    outline: 0px;
    padding:0px;
    margin:0px;
}
.g-dropdown-nav.menu-right>ul,
.g-dropdown-nav.menu-right:hover>ul,
.g-dropdown-nav-selected.menu-right>ul,
.g-dropdown-nav-selected.menu-right:hover>ul {
    left: auto !important; // have to override the left bacuase these menu must be right aligned
    right: 0px;

}
.g-nav-button{
    display: flex;
    align-items: center;
    background:transparent;
    border:0px;
    outline: 0px;
    padding:14px;
    margin:0px;
}
// end

.sg-dropdownmenu-disabled{
    pointer-events:none;
}
// global icon button
.icon-button, .icon-button-selected{
    cursor: pointer;
    margin-top:-2px;
}
.icon-button:hover >:first-child,   .icon-button>:first-child, .icon-button-selected > :first-child{
    margin:0px 4px 2px 4px;

}
.icon-button-selected{
    cursor: pointer;
}
.icon-button:hover{
    cursor: pointer;
}


//
//multi Select Icon
.multiSelectIcon,
.multiSelectIcon-selected
{
 float:right;
}

.multiSelectIcon-selected{
    float:right;
}

//dropdown basic
.g-dropdown-basic-selected,
.g-dropdown-basic{
    position: relative;
    float:right;
}

.g-dropdown-basic-selected > button,
.g-dropdown-basic > button{
    background:transparent;
    color:#000;
    border:0px;
    outline: 0px;
    font-size:$font-size--md;
}

.g-dropdown-basic-selected > ul,
.g-dropdown-basic > ul{
    display:none;
    top:18px;
    box-shadow: 5px 5px 5px 00px #0000004D;
    padding-top:4px;
    z-index: 11000 !important;
}



.g-dropdown-basic-selected > ul > li,
.g-dropdown-basic > ul > li{
    margin:0px;
    padding:0px;
    line-height: 10px;
    margin-left:-32px;
    text-align: left;
    min-width: fit-content;    z-index: 11000 !important;
}

.g-dropdown-basic-selected > ul > li > button,
.g-dropdown-basic > ul > li > button{
    background:transparent;
    border:0px;
    outline: 0px;
    padding: 10px 20px 10px 8px ;
    margin:0px;
    font-size:$font-size--md;
    text-align: left;
    min-width: fit-content;
    display:flex;
    flex:1;white-space: nowrap;    z-index: 11000 !important;
}



.g-dropdown-basic-selected> ul
    {
            display:block !important; 
            list-style: none;
            margin:0px;
            margin-left:0px;
            width:fit-content;
            position: absolute;
            background-color: #fff;
            z-index: 100000;
    
    }
    .g-dropdown-basic-selected > ul > li > button:focus{
      //  text-decoration: underline;
    }

//end dropdown basic

// second row Tabs
    .g-default-tab-padding{
        padding:11px 15px 9px 15px;

    }

// end second row nav buttons
// global tabs
.g-tab, .g-tab-selected{
    border:0px;
    background-color: transparent;
    border: transparent 1px solid;
    margin-left:5px;
    font-size:$font-size--md;
    outline: 0px;
    font-weight: bold;
}
.g-tab-selected{
    pointer-events: none;
    cursor:default;
}
//end
 
body {
    padding: 0px;
    margin: 0px;
    overflow: none !important;
}

.layout {

    display: flex;
    height: 100vh;
     width: 100vw;

    .aside {
        min-width: 52px;
     }

    .main-layout {
        display: flex;
        flex-direction: column;
        flex: 9 1 0;
        overflow: auto !important;

        .top-bar {
            height: 52px;
        }

        .main-content {
            flex: 9 1 0;
            height: 60vh !important;
            overflow: hidden !important;

        }

    }
}

.index {
    width: 100%;
}

.secondColContainer {
    margin: 0px !important;
    padding: 0px !important;
    left:6px;
    width: 98%;
    position: relative;
    height: 100%;
    overflow-y: auto;
    .email-preview-col {
        height: 100%;
        display: flex;
        flex-direction: column; 
       padding-left:5px;
    }
     .email-preview-label {
        margin: 5px;
    }
}




.tabRow {
    display: flex;
}

.secondRow {
    z-index: 999 !important;
    height: 40px;
}

.paddingForRows {
    padding: 0px 20px 0px 11px;

}

.secondRowWorkflow {
 
    z-index: 999 !important;
    height: 40px;
    display: flex;
}

.secondRowWorkflow>div {
    flex: 90 1;
    flex-grow: 95;

}

.secondRowWorkflow>div:nth-child(1) {
    padding: 11px 11px 11px 18px;

    font-weight: bold;
}

.secondRowWorkflow>div:nth-child(2) {
    cursor: pointer;
    flex-grow: 1.5;
    align-self: center;
    // padding:8px 15px 11px 18px;
    // margin-bottom:20px;
}

.pop-wizard-icon {
    pointer-events: all;
    opacity: 1;
    cursor: pointer;
    width: auto;
}

.pop-wizard-icon.popped {
    pointer-events: none;
    opacity: 0;
}

.MyEnquiriesMenuIconHolder {
    padding-Right: 6px;
    margin: -3px 0px 0px 0px;
 
}

.MyEnquiriesMenuIconHolder:hover {
    color: #1e2020;
    cursor: pointer;
}

.paneHeader {
 
    padding: 0px 0px 4px 6px;
    font-weight: bold;
}

.MainFlexRow {
    display: flex;
    padding-top: 10px;

    width: 100% !important;

}

.MainFlexRow>div {
    width: 50%;
    background: solid 1px blue;
}

.firstColFullWidth {
    height: calc(100vh - 135px);
    overflow-y: none;
    overflow-x: none;
    margin-right: 3px;
    margin-left:3px;
}
.disableSelection{-moz-user-select: none !important;
    -khtml-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    pointer-events: none;
}
.secondCol {
    background: #F3F3F3;
    padding: 0px 0px 5px 0px;
    margin: 0px;
    height: calc(100vh - 132px);
}

.firstColFullWidth::-webkit-scrollbar,
.secondCol::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.firstColFullWidth::-webkit-scrollbar-track,
.secondCol::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.firstColFullWidth::-webkit-scrollbar-thumb,
.secondCol::-webkit-scrollbar-thumb {
    background-color: #A5AAB3;
    outline: 0px solid #A5AAB3;
}

#resize {
 
    float: left;
    width: 6px;
    height: 100%;
    cursor: col-resize;
    z-index:1245 !important;
}

#resize:hover {
    border-left: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3;
}

  
 

.side-nav {
    z-index: 111111 !important;
	height: 100vh;
	width: 52px;
	min-height:400px;
	//transition: width 0.4s ease-out;
    display: flex;
	flex-direction: column;
	overflow: hidden;
	gap: 20px;
    position: absolute;
.logo-row{
	.nav-btns {
		flex: 0 0 0;
		overflow: hidden;
		transition: flex 0.4s ease-in;
		transition-delay: 0s;
		justify-self: flex-end;
		display: flex;
		align-items: center;
		justify-content: center;

		.nav-btn {
			width: 15px;
			height: 15px;
		}

		.collapse-nav {
			display: none;
		}

		.expand-nav {
			display: inline-block;
			transform: rotate(90deg);
		}
	}
}
	.label {  
		overflow: hidden;
		flex: 0 0 0;
		transition: flex 0.3s ease-out;
		transition-delay: 0s; 
		
	}

	&--links {
		display: flex;
		flex-direction: column;
 		gap: 1px;
		font-size: 14px;
		&--toplink{
			padding-top: 20px;
		}
		&--link {
			height: 43px;
			display: flex;
			align-items: center;
			border-left: 2px solid transparent;
		}

		&--link-wrap {
			display: flex;
			align-items: center;
			flex: 9 1 0;
			padding: 0px;
			gap: 2px;
			.side-nav--links--link--icon {
				width: 48px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
		 
				.nav-icon {
					width: 16px;
					height: 16px; 
				 
				}
			}
		}
		&--link-wrap:hover {
			cursor: pointer;
			 
		}
	}
}

.side-nav.expanded {
	width: 200px;
	position: static;
	float: none;

	.label {
		flex: 9 1 0;
	}

	.nav-btns {
		flex: 2 1 0;

		.collapse-nav {
			display: inline-block;
		}

		.expand-nav {
			display: none;
		}
	}
}
.side-nav.expanded > div > .ExpandContractRow {
 
	transform: rotate(180deg);
	height:35px;
    float: right;
	margin-bottom:05px;
	padding-left:15px;
	width:200px;
	text-align:left !important;
	font-size:11px;
}



.Filler{
 
	justify-items: end;
	display:flex;
	flex-grow: 1;
}

.ExpandIcon{
	width: 16px;
height: 16px;
}

.EMS-Version {
	position: relative;
	top: -20px;
	left: 35px;
	width: 150px;
	font-size: small;
}